import React, { Component } from 'react';
import './inicio.scss'
import './script'
export default class Inicio extends Component {
    constructor(props){
        super(props)
        this.state = {
            gadgets:[
                {title:'Contacto',icon:require('../assets/images/contacto.png').default,link:require("../assets/images/Eduardo-Vargas.vcf").default},
                {title:'Whatsapp',icon:require('../assets/images/whatsapp.png').default,link:'https://api.whatsapp.com/send?phone=+51948895708'},
                {title:'Correo',icon:require('../assets/images/correo.png').default,link:'mailto:eduardo@formulaperu.com'},
                {title:'Teléfono',icon:require('../assets/images/telefono.png').default,link:'tel:+51948895708'},
                {title:'Instagram',icon:require('../assets/images/instagram.png').default,link:'https://www.instagram.com/eduardovargasparedes/'},
                {title:'LinkedIn',icon:require('../assets/images/linkedin.png').default,link:'https://www.linkedin.com/in/eduardo-vargas-paredes-b2552390/'},
                {title:'Web',icon:require('../assets/images/formula.svg').default,link:'https://www.formulaperu.com'},
            ]
        }
    }
    render() {
    return (
        <div className="inicio">
            <canvas id="lamp-anim" class="lamp-anim" width="100%" height="100%"></canvas>
            <div className='container'>
                <div className='content-profile'>
                    <div className='profile'>
                        <img className='img' src={require('../assets/images/perfil.png').default} ></img>
                    </div>
                    <div className='text-profile'>
                        <span className='title-profile'>Eduardo Vargas</span>
                        <p className='paragraph'>Asesor en Marketing Digital y apasionado del Diseño<br></br> CEO en Fórmula Agencia</p>
                        <p className='paragraph'> <img className='' src={require('../assets/images/cords.svg').default} ></img> Lima, Perú</p>
                    </div>
                </div>
                <div className='content-gadgets'>
                    {
                        this.state.gadgets.map((item)=>{
                            return(
                            <a href={item.link} target="_blank" className='gadget'>
                                <div className='gadget_img'>
                                    <img className='img-gadget' src={item.icon} ></img>
                                </div>
                                <span className='title-gadget'>{item.title}</span>
                            </a>
                            )
                        })
                    }
                </div>    
                <div className='fotter'>
                    Desarrollado con ♥ por Fórmula Agencia
                </div>
            </div>
        </div>
    );
  }
}
