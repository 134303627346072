import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import Inicio from './pages/inicio'
import $ from 'jquery'
window.$ = $
window.jQuery = window.$
export default class Routes extends Component {
  render() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Inicio></Inicio>
                </Route>
            </Switch>
        </Router>
    )
  }
}

